import React from "react";
import { Parallax } from "react-scroll-parallax";

import GreenMotionPhone from "../../assets/images/application-development/green-motion-phone.png";
import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import { FullWidthColumnWrapper } from "../confines-wrappers";
import Icon from "../icon";
import Link from "../link";
import Panel from "../panel-wrapper";

const CaseStudyContainer = styled.div`
  overflow: hidden;

  .case-study-wrapper {
    .case-study-content {
      transition: 0.2s ease-in-out;
      position: relative;
      padding-top: 80%;

      .case-study-copy-wrapper {
        position: relative;
        z-index: 2;

        h2,
        p {
          text-align: center;
        }

        h2 {
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
          letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
          text-transform: uppercase;
        }

        .copy {
          padding-top: 2.6rem;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }

        .link {
          padding-top: 3.6rem;
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "regular"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }
      }

      .case-study-parallax-container {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        padding-top: 74%;
        width: 100%;

        .case-study-parallax-wrapper {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;

          .parallax-outer {
            p {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "white"
    })};
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_1"
    })};
              font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "regular"
    })};
              line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};

              .item {
                display: flex;
                align-items: center;
                position: relative;
                padding-right: 3.8rem;
                min-height: 3rem;

                .text {
                  width: 100%;
                }

                .icon-wrapper {
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                  width: 3rem;
                  height: 3rem;
                  background: rgba(255, 255, 255, 0.9);
                  border: 2px solid
                    ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
                  border-radius: 100%;
                  fill: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandThreeInverse"
    })};

                  .icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 56%;
                    height: 56%;
                  }
                }
              }
            }

            &.green-motion-phone {
              img {
                margin: 0 auto;
                width: 34%;
              }
            }

            &.annotation {
              position: absolute;
              top: 0%;
              width: 42%;

              &.driving-license,
              &.unlock {
                left: 0;
              }

              &.driving-license {
                top: 24%;
              }

              &.unlock {
                top: auto;
                bottom: 24%;
              }

              &.queues {
                top: 50%;
                right: 0;
              }
            }

            &.driving-license,
            &.unlock {
              p {
                text-align: right;
              }
            }

            &.queues {
              p {
                .item {
                  padding-left: 3.8rem;
                  padding-right: 0;

                  .icon-wrapper {
                    left: 0;
                    right: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 375px) {
    .case-study-wrapper {
      .case-study-content {
        padding-top: 78%;

        .case-study-parallax-container {
          padding-top: 68%;

          .case-study-parallax-wrapper {
            .parallax-outer {
              &.green-motion-phone {
                img {
                  width: 33%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 480px) {
    .case-study-wrapper {
      .case-study-content {
        padding-top: 74%;

        .case-study-copy-wrapper {
          .link {
            padding-top: 4.2rem;
          }
        }

        .case-study-parallax-container {
          .case-study-parallax-wrapper {
            .parallax-outer {
              p {
                font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_2"
    })};

                .item {
                  padding-right: 5.2rem;
                  min-height: 4rem;

                  .icon-wrapper {
                    width: 4rem;
                    height: 4rem;
                  }
                }
              }

              &.queues {
                p {
                  .item {
                    padding-left: 5.2rem;
                    padding-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 568px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-parallax-container {
          .case-study-parallax-wrapper {
            .parallax-outer {
              &.annotation {
                width: 41%;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    .case-study-wrapper {
      .case-study-content {
        padding-top: 88%;

        .case-study-copy-wrapper {
          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }

          .copy {
            padding-top: 3.2rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
          }

          .link {
            padding-top: 6.2rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }

        .case-study-parallax-container {
          padding-top: 78%;

          .case-study-parallax-wrapper {
            .parallax-outer {
              p {
                font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
              }

              &.green-motion-phone {
                img {
                  width: 42%;
                }
              }

              &.annotation {
                width: 36%;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .case-study-wrapper {
      .case-study-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 0;

        .case-study-copy-wrapper,
        .case-study-parallax-container {
          width: 50%;
        }

        .case-study-copy-wrapper {
          padding-right: 4%;

          h2,
          p {
            text-align: left;
          }

          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }

          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
          }

          .link {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }

        .case-study-parallax-container {
          position: relative;
          margin-top: 2%;
          margin-bottom: 2%;
          padding-top: 40%;
          padding-left: 4%;

          .case-study-parallax-wrapper {
            .parallax-outer {
              p {
                font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};

                .item {
                  padding-right: 3.8rem;
                  min-height: 3rem;

                  .icon-wrapper {
                    width: 3rem;
                    height: 3rem;
                  }
                }
              }

              &.green-motion-phone {
                img {
                  width: 44%;
                }
              }

              &.annotation {
                width: 38%;
              }

              &.queues {
                p {
                  .item {
                    padding-left: 3.8rem;
                    padding-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
          }
        }

        .case-study-parallax-container {
          .case-study-parallax-wrapper {
            .parallax-outer {
              p {
                font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};

                .item {
                  padding-right: 5.2rem;
                  min-height: 4rem;

                  .icon-wrapper {
                    width: 4rem;
                    height: 4rem;
                  }
                }
              }

              &.green-motion-phone {
                img {
                  width: 46%;
                }
              }

              &.annotation {
                width: 36%;
              }

              &.queues {
                p {
                  .item {
                    padding-left: 5.2rem;
                    padding-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            padding-top: 3.8rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_5"
    })};
          }

          .link {
            padding-top: 6.8rem;
          }
        }

        .case-study-parallax-container {
          .case-study-parallax-wrapper {
            .parallax-outer {
              &.green-motion-phone {
                img {
                  width: 44%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_6"
    })};
          }
        }

        .case-study-parallax-container {
          .case-study-parallax-wrapper {
            .parallax-outer {
              p {
                font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};

                .item {
                  padding-right: 6.8rem;
                  min-height: 5rem;

                  .icon-wrapper {
                    width: 5rem;
                    height: 5rem;
                  }
                }
              }

              &.green-motion-phone {
                img {
                  width: 45%;
                }
              }

              &.annotation {
                width: 37%;
              }

              &.queues {
                p {
                  .item {
                    padding-left: 6.8rem;
                    padding-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-parallax-container {
          .case-study-parallax-wrapper {
            .parallax-outer {
              p {
                font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};

                .item {
                  padding-right: 7.8rem;
                  min-height: 6rem;

                  .icon-wrapper {
                    width: 6rem;
                    height: 6rem;
                  }
                }
              }

              &.green-motion-phone {
                img {
                  width: 45%;
                }
              }

              &.queues {
                p {
                  .item {
                    padding-left: 7.8rem;
                    padding-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GreenMotionCaseStudy = () => (
  <CaseStudyContainer className="case-study-container">
    <Panel gradient="blueGreen">
      <FullWidthColumnWrapper className="case-study-wrapper content-container">
        <div className="case-study-content">
          <div className="case-study-copy-wrapper">
            <h2>
              Green Motion
            </h2>

            <p className="copy">
              Streamlining the car rental process
            </p>

            <p className="link">
              <span className="off-screen">
                Click here to
                {" "}
              </span>

              <Link
                type="styled"
                color="white"
                href="/projects/green-motion"
              >
                View Project
              </Link>
            </p>
          </div>

          <div className="case-study-parallax-container">
            <div className="case-study-parallax-wrapper">
              <Parallax
                className="green-motion-phone"
                y={[ -10, 10 ]}
                tagOuter="figure"
              >
                <img
                  src={GreenMotionPhone}
                  alt="An iPhone with the unlock car screen on the Green Motion App"
                />
              </Parallax>

              <Parallax
                className="annotation driving-license"
                y={[ -30, 30 ]}
              >
                <p>
                  <span className="item">
                    <span className="text">
                      Scan your documents for verification
                    </span>

                    <span className="icon-wrapper">
                      <Icon
                        className="icon"
                        icon="greenmotionDrivingLicense"
                      />
                    </span>
                  </span>
                </p>
              </Parallax>

              <Parallax
                className="annotation queues"
                y={[ -40, 40 ]}
              >
                <p>
                  <span className="item">
                    <span className="text">
                      No more queues, no more paperwork at the desk
                    </span>

                    <span className="icon-wrapper">
                      <Icon
                        className="icon"
                        icon="greenmotionDocument"
                      />
                    </span>
                  </span>
                </p>
              </Parallax>

              <Parallax
                className="annotation unlock"
                y={[ -40, 40 ]}
              >
                <p>
                  <span className="item">
                    <span className="text">
                      Go straight to your car and unlock with the app
                    </span>

                    <span className="icon-wrapper">
                      <Icon
                        className="icon"
                        icon="greenmotionPadlock"
                      />
                    </span>
                  </span>
                </p>
              </Parallax>
            </div>
          </div>
        </div>
      </FullWidthColumnWrapper>
    </Panel>
  </CaseStudyContainer>
);

export default GreenMotionCaseStudy;
