import React from "react";
import { Parallax } from "react-scroll-parallax";

import WesternPowerSplash from "../../assets/images/application-development/western-power-splash.jpg";
import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import { FullWidthColumnWrapper } from "../confines-wrappers";
import Icon from "../icon";
import Link from "../link";
import Panel from "../panel-wrapper";

const CaseStudyContainer = styled.div`
  overflow: hidden;

  .case-study-wrapper {
    .case-study-content {
      transition: 0.2s ease-in-out;
      position: relative;
      padding-top: 98%;

      .case-study-copy-wrapper {
        position: relative;
        z-index: 2;

        h2,
        p {
          text-align: center;
        }

        h2 {
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
          letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
          text-transform: uppercase;
        }

        .copy {
          padding-top: 2.6rem;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }

        .link {
          padding-top: 3.6rem;
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "regular"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }
      }

      .case-study-parallax-container {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        padding-top: 24rem;
        width: 100%;

        .case-study-parallax-wrapper {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 25rem;

          ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0;
            list-style: none;
            color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
            text-align: right;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
            font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "bold"
    })};
            line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};

            li {
              padding-top: 2rem;
              width: 100%;

              &:first-child {
                padding-top: 0;
              }

              .item {
                display: flex;
                align-items: center;
                position: relative;
                padding-right: 5.8rem;
                min-height: 4rem;

                .text {
                  width: 100%;
                }

                .icon-wrapper {
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                  width: 4rem;
                  height: 4rem;
                  background: #dddddd;
                  border: 2px solid
                    ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
                  border-radius: 100%;
                  fill: #10607c;

                  .icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 56%;
                    height: 56%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 375px) {
    .case-study-wrapper {
      .case-study-content {
        padding-top: 86%;
      }
    }
  }

  @media (min-width: 480px) {
    .case-study-wrapper {
      .case-study-content {
        padding-top: 68%;

        .case-study-copy-wrapper {
          .link {
            padding-top: 4.2rem;
          }
        }
      }
    }
  }

  @media (min-width: 568px) {
    .case-study-wrapper {
      .case-study-content {
        padding-top: 58%;
      }
    }
  }

  @media (min-width: 600px) {
    .case-study-wrapper {
      .case-study-content {
        padding-top: 36%;

        .case-study-copy-wrapper {
          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }

          .copy {
            padding-top: 3.2rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
          }

          .link {
            padding-top: 6.2rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }

        .case-study-parallax-container {
          padding-top: 15rem;

          .case-study-parallax-wrapper {
            width: 100%;

            ul {
              li {
                width: 50%;

                &:nth-child(1n) {
                  padding-top: 1.2rem;
                }

                &:nth-child(odd) {
                  padding-right: 4%;
                }

                &:nth-child(even) {
                  padding-left: 4%;
                }

                .item {
                  padding-right: 6.8rem;
                  min-height: 5rem;

                  .icon-wrapper {
                    width: 5rem;
                    height: 5rem;

                    .icon {
                      width: 2.5rem;
                      height: 2.5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .case-study-wrapper {
      .case-study-content {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding-top: 0;

        .case-study-copy-wrapper,
        .case-study-parallax-container {
          width: 50%;
        }

        .case-study-copy-wrapper {
          margin-left: 4%;

          h2,
          p {
            text-align: left;
          }

          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }

          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
          }

          .link {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }

        .case-study-parallax-container {
          position: relative;
          margin-top: 6%;
          margin-bottom: 6%;
          padding-top: 26.8rem;
          margin-right: 4%;

          .case-study-parallax-wrapper {
            width: 100%;

            ul {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};

              li {
                width: 100%;

                &:nth-child(1n) {
                  padding-top: 1.2rem;
                }

                &:first-child {
                  padding-top: 0;
                }

                &:nth-child(odd) {
                  padding-right: 0%;
                }

                &:nth-child(even) {
                  padding-left: 0%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
          }
        }

        .case-study-parallax-container {
          margin-top: 8%;
          margin-bottom: 8%;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            padding-top: 3.8rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_5"
    })};
          }

          .link {
            padding-top: 6.8rem;
          }
        }

        .case-study-parallax-container {
          margin-top: 24%;
          margin-bottom: 4%;
          padding-top: 15rem;

          .case-study-parallax-wrapper {
            width: 100%;

            ul {
              li {
                width: 50%;

                &:nth-child(1n) {
                  padding-top: 1.2rem;
                }

                &:nth-child(odd) {
                  padding-right: 2%;
                }

                &:nth-child(even) {
                  padding-left: 2%;
                }

                .item {
                  padding-right: 6.2rem;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_6"
    })};
          }
        }

        .case-study-parallax-container {
          .case-study-parallax-wrapper {
            ul {
              li {
                &:nth-child(1n) {
                  padding-top: 1.8rem;
                }

                .item {
                  padding-right: 7.8rem;
                  min-height: 6rem;

                  .icon-wrapper {
                    width: 6rem;
                    height: 6rem;

                    .icon {
                      width: 3rem;
                      height: 3rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const WesternPowerCaseStudy = () => (
  <CaseStudyContainer className="case-study-container">
    <Panel
      backgroundImage={WesternPowerSplash}
      parallax
    >
      <FullWidthColumnWrapper className="case-study-wrapper content-container">
        <div className="case-study-content">
          <div className="case-study-copy-wrapper">
            <h2>
              Western Power Distribution
            </h2>

            <p className="copy">
              Search and report power outages in your area
            </p>

            <p className="link">
              <span className="off-screen">
                Click here to
                {" "}
              </span>

              <Link
                type="styled"
                color="white"
                href="/projects/western-power-distribution/"
              >
                View Project
              </Link>
            </p>
          </div>

          <div className="case-study-parallax-container">
            <div className="case-study-parallax-wrapper">
              <Parallax y={[ -44, 44 ]}>
                <ul>
                  <li>
                    <span className="item">
                      <span className="text">
                        Set up live alerts for your home and business
                      </span>

                      <span className="icon-wrapper">
                        <Icon
                          className="icon"
                          icon="wpdBell"
                        />
                      </span>
                    </span>
                  </li>

                  <li>
                    <span className="item">
                      <span className="text">
                        Report power outages
                      </span>

                      <span className="icon-wrapper">
                        <Icon
                          className="icon"
                          icon="wpdPowerCut"
                        />
                      </span>
                    </span>
                  </li>

                  <li>
                    <span className="item">
                      <span className="text">
                        Live power outage map
                      </span>

                      <span className="icon-wrapper">
                        <Icon
                          className="icon"
                          icon="wpdLocationPin"
                        />
                      </span>
                    </span>
                  </li>

                  <li>
                    <span className="item">
                      <span className="text">
                        Support team dashboard
                      </span>

                      <span className="icon-wrapper">
                        <Icon
                          className="icon"
                          icon="wpdChat"
                        />
                      </span>
                    </span>
                  </li>
                </ul>
              </Parallax>
            </div>
          </div>
        </div>
      </FullWidthColumnWrapper>
    </Panel>
  </CaseStudyContainer>
);

export default WesternPowerCaseStudy;
