import React from "react";
import { Parallax } from "react-scroll-parallax";

import GakuScreen1 from "../../assets/images/application-development/gaku-screen-1.png";
import GakuScreen2 from "../../assets/images/application-development/gaku-screen-2.png";
import GakuScreen3 from "../../assets/images/application-development/gaku-screen-3.png";
import GakuScreenShadow from "../../assets/images/application-development/gaku-screen-shadow.png";
import { FullWidthColumnWrapper } from "../confines-wrappers";
import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import Link from "../link";
import Panel from "../panel-wrapper";

const CaseStudyContainer = styled.div`
  overflow: hidden;

  .case-study-wrapper {
    .case-study-content {
      transition: 0.2s ease-in-out;
      position: relative;
      padding-top: 96%;

      .case-study-copy-wrapper {
        position: relative;
        z-index: 2;

        h2,
        p {
          text-align: center;
        }

        h2 {
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
          letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
          text-transform: uppercase;
        }

        .copy {
          padding-top: 2.6rem;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }

        .link {
          padding-top: 3.6rem;
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "regular"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }
      }

      .case-study-parallax-container {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        padding-top: 96%;
        width: 100%;

        .case-study-parallax-wrapper {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 86%;

          .parallax-outer {
            &:nth-child(1) {
              z-index: 4;
            }

            &:nth-child(2) {
              z-index: 3;
            }

            &:nth-child(3) {
              z-index: 2;
            }

            &:nth-child(4) {
              z-index: 2;
            }
          }

          .case-study-parallax,
          .case-study-static-parallax {
            top: 4rem;

            img {
              user-select: none;
            }
          }

          .case-study-static-parallax {
            position: relative;
          }

          .case-study-parallax {
            position: absolute;
          }
        }
      }
    }
  }

  @media (min-width: 480px) {
    .case-study-wrapper {
      .case-study-content {
        padding-top: 86%;

        .case-study-copy-wrapper {
          .link {
            padding-top: 4.2rem;
          }
        }

        .case-study-parallax-container {
          padding-top: 86%;
        }
      }
    }
  }

  @media (min-width: 600px) {
    .case-study-wrapper {
      .case-study-content {
        padding-top: 72%;

        .case-study-copy-wrapper {
          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }

          .copy {
            padding-top: 3.2rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
          }

          .link {
            padding-top: 6.2rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }

        .case-study-parallax-container {
          padding-top: 72%;

          .case-study-parallax-wrapper {
            width: 72%;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .case-study-wrapper {
      .case-study-content {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding-top: 0;

        .case-study-copy-wrapper,
        .case-study-parallax-container {
          width: 50%;
        }

        .case-study-copy-wrapper {
          padding-left: 4%;

          h2,
          p {
            text-align: left;
          }

          h2 {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }

          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          }

          .link {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }

        .case-study-parallax-container {
          position: relative;
          margin-top: 2%;
          margin-bottom: 2%;
          padding-top: 38%;
          padding-right: 4%;

          .case-study-parallax-wrapper {
            width: 86%;
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            padding-top: 3.8rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
          }

          .link {
            padding-top: 6.8rem;
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .case-study-wrapper {
      .case-study-content {
        .case-study-copy-wrapper {
          .copy {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_5"
    })};
          }
        }
      }
    }
  }
`;

const GakuCaseStudy = () => (
  <CaseStudyContainer className="case-study-container">
    <Panel gradient="pinkPurple">
      <FullWidthColumnWrapper className="case-study-wrapper content-container">
        <div className="case-study-content">
          <div className="case-study-copy-wrapper">
            <h2>
              Gaku
            </h2>

            <p className="copy">
              A simple, elegant video lesson app for pilates instructors
            </p>

            <p className="link">
              <span className="off-screen">
                Click here to
                {" "}
              </span>

              <Link
                type="styled"
                color="white"
                href="/projects/gaku/"
              >
                View Project
              </Link>
            </p>
          </div>

          <div className="case-study-parallax-container">
            <div className="case-study-parallax-wrapper">
              <Parallax
                className="case-study-parallax"
                x={[ 0, 15 ]}
                y={[ 0, -40 ]}
                tagOuter="figure"
              >
                <img
                  className="full"
                  src={GakuScreen1}
                  alt="Gaku app dashboard"
                />
              </Parallax>

              <Parallax
                className="case-study-static-parallax"
                tagOuter="figure"
              >
                <img
                  className="full"
                  src={GakuScreen2}
                  alt="Gaku app invitation screen"
                />
              </Parallax>

              <Parallax
                className="case-study-parallax"
                x={[ 0, -15 ]}
                y={[ 0, 40 ]}
                tagOuter="figure"
              >
                <img
                  className="full"
                  src={GakuScreen3}
                  alt="Gaku app splash screen (logo)"
                />
              </Parallax>

              <Parallax
                className="case-study-parallax"
                x={[ 0, 40 ]}
                y={[ 0, -15 ]}
                tagOuter="figure"
              >
                <img
                  className="full"
                  src={GakuScreenShadow}
                  alt="A shadow effect"
                />
              </Parallax>
            </div>
          </div>
        </div>
      </FullWidthColumnWrapper>
    </Panel>
  </CaseStudyContainer>
);

export default GakuCaseStudy;
