import { graphql } from "gatsby";
import * as React from "react";
import styled from "styled-components";

import VideoBackgroundImage from "../assets/images/application-development/app-video-backround-fallback.jpg";
import {
  GakuCaseStudy,
  GreenMotionCaseStudy,
  WesternPowerCaseStudy
} from "../components/application-development";
import Testimonials from "../components/Testimonials";
import { IArticleData } from "../components/articles-grid/articles-grid";
import { FullWidthColumnWrapper } from "../components/confines-wrappers";
import CtaButton from "../components/cta/cta-button";
import Footer from "../components/footer";
import GetInTouch from "../components/get-in-touch";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import HeroVideo from "../components/hero-video";
import { breadCrumbSchema } from "../components/html-schema-snippets";
import Insights from "../components/insights";
import Layout from "../components/layout";
import PageTop from "../components/page-top";
import TextPanel from "../components/text-panel";
import getProfile from "../templates/utils/getProfile";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../theme/theme-helper";
import {
  AppsterAward,
  MediaInnovationAward
} from "../components/awards";
import { IInsightsArticleData } from "./insights";

interface IProps {
  data: IApplicationDevelopmentProps;
}

export interface IApplicationDevelopmentProps {
  allNodeLabsArticle: {
    edges: IInsightsProps[];
  };
}

interface IInsightsProps {
  node: IInsightsArticleData;
}

const PageTopCustom = styled.div`
  .top-content-container {
    .top-content-wrapper {
      position: absolute;
      left: 0;
      top: 0;

      .top-content {
        z-index: 3;
        text-align: center;

        .heading {
          transition: 0.2s ease-in-out;
          display: block;
          margin: 0 auto;
          width: 26rem;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }

        .cta-button {
          padding-top: 6%;
        }
      }

      .appster-award,
      .media-innovation-award {
        position: absolute;
        z-index: 3;
        bottom: 2rem;
        max-width: 25.8rem;
        width: 14%;
        opacity: 0.75;
      }

      .appster-award {
        left: 2rem;
      }

      .media-innovation-award {
        right: 2rem;
      }
    }
  }

  @media (min-width: 375px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 32rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          }

          .cta-button {
            padding-top: 6%;
          }
        }

        .appster-award,
        .media-innovation-award {
          width: 16%;
        }
      }
    }
  }

  @media (min-width: 480px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 39rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
          }
        }

        .appster-award,
        .media-innovation-award {
          width: 9.6rem;
        }
      }
    }
  }

  @media (min-width: 568px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 53rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    .top-content-container {
      .top-content-wrapper {
        .appster-award,
        .media-innovation-award {
          width: 20%;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 60rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
          }
        }

        .appster-award,
        .media-innovation-award {
          bottom: 5%;
          width: 15.4rem;
        }
      }
    }
  }

  @media (min-width: 960px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 66rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_5"
    })};
          }

          .cta-button {
            padding-top: 5.2rem;
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .top-content-container {
      .top-content-wrapper {
        .appster-award,
        .media-innovation-award {
          width: 16%;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 74rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_6"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 80rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_7"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 96rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_9"
    })};
          }
        }
      }
    }
  }
`;

const AppPage = ({ data }: IProps) => {
  const canonicalPath = "/application-development";

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Application Development",
      path: canonicalPath
    }
  ]);

  const schemaJson = [ breadcrumbsSchemaData ];

  return (
    <Layout>
      <>
        <HelmetWrapper
          title="Application development - Bespoke mobile apps"
          description="We make awesome mobile and web apps"
          canonicalPath={canonicalPath}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header role="banner">
          <Header
            initialLogo
            initialTheme="dark"
          />

          <PageTopCustom id="hero">
            <PageTop
              style={{
                paddingTop: 0,
                paddingBottom: 0
              }}
              height="large"
            >
              <HeroVideo
                src="https://cdn.gravitywell.co.uk/videos/application-development/Landing-Page-Apps_04.m3u8"
                fallbackImage={VideoBackgroundImage}
              />

              <FullWidthColumnWrapper className="top-content">
                <p className="heading">
                  We make awesome mobile and web apps
                </p>

                <CtaButton
                  type="primary"
                  href="#get-in-touch"
                  text="Work with us"
                />
              </FullWidthColumnWrapper>

              <AppsterAward className="appster-award" />

              <MediaInnovationAward className="media-innovation-award" />
            </PageTop>
          </PageTopCustom>
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            Application Development
          </h1>

          <GakuCaseStudy />

          <aside>
            <TextPanel
              label="Working With Us"
              text={(
                <p>
                  A pre-project discovery phase allows us to work out exactly
                  what is required and put a solid strategy in place.
                </p>
              )}
              icon="telescope"
            />
          </aside>

          <WesternPowerCaseStudy />

          <aside>
            <TextPanel
              label="Working With Us"
              text={(
                <p>
                  Rapid prototypes of concepts mean we can gain early feedback
                  and insights.
                </p>
              )}
              icon="spaceBuggy"
            />
          </aside>

          <GreenMotionCaseStudy />

          <Testimonials />

          <aside>
            <section className="latest-container">
              <h2 className="off-screen">
                Latest posts
              </h2>

              <Insights
                title="Insights"
                gradient="orangePink"
                articles={data.allNodeLabsArticle.edges.map(post => {
                  const profile = getProfile(post.node.relationships.author);

                  const articleObj: IArticleData = {
                    created: post.node.created,
                    path: post.node.path.alias,
                    title: post.node.title,
                    author: profile.field_full_name,
                    cover: post.node.relationships.field_cover_image
                  };

                  return articleObj;
                })}
              />
            </section>
          </aside>
        </main>

        <Styled.FloatingCTA>
          <CtaButton
            type="primary"
            href="#get-in-touch"
            text="Work with us"
            className="floating-cta"
          />
        </Styled.FloatingCTA>

        <footer
          role="contentinfo"
          style={{
            position: "relative",
            zIndex: 3
          }}
        >
          <GetInTouch />

          <Footer />
        </footer>
      </>
    </Layout>
  );
};

const Styled = {
  FloatingCTA: styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    margin: 2rem;
  `
};

export default AppPage;

export const query = graphql`
  query applicationDevelopmentPageQuery {
    allNodeLabsArticle(
      filter: { drupal_internal__nid: { in: [162, 171, 178] } }
    ) {
      edges {
        node {
          ...getLabsData
        }
      }
    }
  }
`;
